import '../App.css';
import NavBar from '../components/NavBar';
import ProjectCard from '../components/ProjectCard';

export default function Projects() {
    return (
        <div className='page'>
            <NavBar/>

            <ProjectCard 
            title="SaunaBooker"
            text="Pythonilla ja Flaskilla toteutettu saunavuorojen varaamispalvelu. Sovellus on hostattu AWS:n Lightsail palvelussa ja tietokantana on AWS:n DynamoDB."
            url="https://sauna-service.8d83590l223hm.eu-north-1.cs.amazonlightsail.com/login"
            />

            <ProjectCard 
            title="Match the color"
            text="Hyperkasuaali mobiilipeli, joka on kehitetty Solar2D-pelimoottorilla ja kirjoitettu Lua-ohjelmointikielellä. Tällä hetkellä peliä ei ole saatavilla sovelluskaupoissa, mutta sitä voi testata selaimessa alla olevasta painikkeesta."
            url="http://matchthecolor.kuumobile.com"
            />
            
        </div>
    )
}