import '../App.css';
import NavBar from '../components/NavBar';
import profilePic from '../assets/profile-pic.jpg'
import pushPin from '../assets/push-pin.png'
import school from '../assets/school.png'
import code from '../assets/code.png'
import { Component, useEffect } from 'react';

function Home() {
  useEffect(() => {
    
  }, [])
  return (
    <div className="page">
      <NavBar/>

      <div className='column-container'>
        <div id='profile-pic-container'>
          <img src={profilePic}></img>
          <p>Olli Peltomaa</p>
        </div>

        <div className='info-text-container'>
          <div>
            <img src={school}/>
            <p className='big-text'>LUONNONTIETEIDEN KANDIDAATTI</p>
          </div>
          <div>
            <img src={code}/>
            <p className='big-text'>OHJELMISTOKEHITTÄJÄ</p>
          </div>
          <div>
            <img src={pushPin}/>
            <p className='big-text'>JYVÄSKYLÄ</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;
