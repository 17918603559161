import { useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function NavBar() {

    const [activeLink, setActiveLink] = useState(0)

    return (
        <div id="nav-bar">
            <NavLink to='/'>KOTI</NavLink>
            <NavLink to='/projects'>PROJEKTIT</NavLink>
            <NavLink to='/education'>KOULUTUS</NavLink>
        </div>
    )
}