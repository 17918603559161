
export default function ProjectCard(props) {

    return (
        <div className="project-card">
            <h3>{props.title}</h3>
            <p>{props.text}</p>
            <div>
                <a className='link-button' href={props.url}>Projektiin</a>
            </div>
        </div>
    )
}