import '../App.css';
import NavBar from '../components/NavBar';
import agora from '../assets/agora.jpg'

export default function Education() {
    return (
        <div className='page'>
            <NavBar/>
            <div className='column-container'>
                <div id='agora-img-container'>
                    <img src={agora}></img>
                    <p>Koulutus</p>
                </div>
                <div className='long-text'>
                    <p>
                        Valmistuin kesäkuussa 2023 luonnontieteiden kandidaatiksi 
                        Jyväskylän yliopistosta pääaineenani tietotekniikka ja sivuaineenani
                        tilastotiede. Kandidaatintutkielmani aihe oli <i>"Automaattisen verkkoharavoinnin menetelmät ja 
                        haasteet"</i>. Jos mielenkiinto heräsi, voit käydä lukemassa sen 
                        <a id='link' href='http://urn.fi/URN:NBN:fi:jyu-202305122996'> täältä</a>.
                    </p>
                    <p>
                        Jatkan syksyllä 2023 tietotekniikan maisteriopintoihin, ja tavoitteenani on valmistua filosofian
                        maisteriksi kesällä 2025. Maisteriopinnoissani olen suuntautunut ohjelmisto- ja tietoliikennetekniikkaan.
                    </p>
                </div>
            </div>
        </div>
    )
}