import NavBar from "../components/NavBar"

export default function NoPage() {
    return (
        <div className="page">
            <NavBar/>
            <div className="centered-text">
                <h1>404</h1>
                <p>Sivua ei löytynyt</p>
            </div>
        </div>
    )
}